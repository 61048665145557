/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #004b91;
// $warning: #f8991d;
$info: #dd8912;

$navbar-height: 9vh;
$footer-height: 26vh;

.theme-navbar {
    min-height: $navbar-height;
    background-color: #1F5A83;
    color: #fff;

    .nav-link {
        color: #fff;
    }

    .nav-link:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #dd8912;
        border-radius: 2px;
    }
}

#wrapper {
    min-height: 100 - $navbar-height - $footer-height;
}

.theme-footer {
    min-height: $footer-height;
    background-color: #1F5A83;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #C9733C;
        // background-color: #C9733C;
    }
}

.brand-logo {
    height: 70px;
    width: auto;
}

// SWIPPER STYLES
.swiper-pagination-bullet-active {
    background-color: #004b91 !important;
  }
  .swiper-button-prev {
    color: #004b91 !important;
  }
  .swiper-button-next {
    color: #004b91 !important;
  }
  .swiper-wrapper{
      height: 94% !important; 
  }

.justificado {
  text-align: justify;
  a{
      color: #0F2CB3;
      font-weight: bold;
  }
}

// PAGINATION STYLES
.page-link:hover{
    background-color: rgb(226, 119, 52) !important; 
    color: #fff; 
}
.page-link.active, .active > .page-link {
    color: #fff !important;  
    background-color: #dd8912 !important;
    border-color: #dd8912 !important;
}
/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"